import React from "react";
import PropTypes from "prop-types";
// import { graphql, useStaticQuery } from "gatsby";
import { Container, MasterTitle, Title, P, A } from "../../common/info/style";

const Info = props => {
  const scrollToAnchor = anchorName => {
    if (anchorName) {
      if (typeof window !== `undefined`) {
        let anchorElement = document.getElementById(anchorName);
        if (anchorElement) {
          anchorElement.scrollIntoView(100);
        }
      }
    }
  };
  scrollToAnchor("pos" + props.position);

  return (
    <Container>
      <A id="pos1"></A>
      <MasterTitle>Java开发工程师</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、 根据支付产品需要完成产品的设计、研发及维护；</P>
      <P>2、负责公司底层核心模块的设计和编码；</P>
      <P>3、相关设计开发文档的整理和编写；</P>
      <P>4、与产品、运营等业务人员及测试工程师协同工作；</P>
      <P>5、负责公司产品创新和技术创新；</P>
      <P>6、了解大型网站或网络系统的运行瓶颈、以及对应的技术和解决方案；</P>
      <P>7、具有较强的责任心、性格开朗，问题分析能力以及团队合作能力。</P>
      <Title>职位要求:</Title>
      <P>1、 本科及以上学历，熟练使用git版本控制系统，以及linux命令；</P>
      <P>2、扎实的JAVA语言基础，具有良好的编码习惯；</P>
      <P>3、熟悉Mysql 等关系型数据库、以及Elasticsearch、Redis 等非关系型数据库；</P>
      <P>4、熟练掌握微服务技术栈，包括SpringBoot、SpringCloud、RPC框架等；</P>
      <P>5、熟悉RocketMq、 Kafaka等消息中间件；</P>
      <P>6、1、 扎实的后端基础：数据结构、多线程、内存、JDK。</P>

      <A id="pos2"></A>

      <MasterTitle>web前端开发工程师</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、根据产品设计需求，配合后台开发人员实现产品界面和功能，维护及优化前端页面性能；</P>
      <P>2、参与设计并编写WEB前端架构及应用，设计和完成通用业务组件库的搭建工作；</P>
      <P>3、负责与产品、UI和后端做良好的沟通，对产品能够提供持续的体验优化。</P>
      <Title>岗位要求:</Title>
      <P>1、统招本科及以上学历；</P>
      <P>2、扎实的前端基础，优秀的编码习惯；</P>
      <P>3、对前端工程化有较好的理解，对网络、安全、性能优化有较好的理解；</P>
      <P>4、技术栈要求：对Vue、React、Webpack有深入的理解；</P>
      <P>5、有后端经验（Node、Java等）经验者优先。</P>

      <A id="pos3"></A>
      <MasterTitle>风控策略分析师</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、负责OPay业务产品分析、业务逻辑分析、案件溯源等方法，对虚假交易、作弊、支付欺诈等风险进行识别，特征提取；</P>
      <P>2、建立风险数据监测模型，对于风险防范策略反馈个人的观点和认知；</P>
      <P>3、对接产研、业务运营部门，推动策略持续优化；</P>
      <Title>职位要求:</Title>
      <P>1、本科及以上学历，有支付、电商、信贷风控策略或数据分析实习工作经验；</P>
      <P>2、具备一定的风控策略体系认知； </P>
      <P>3、对数据有较高敏感度，反欺诈逻辑思维能力强；</P>
      <P>4、良好的沟通和项目推动能力，保证风控策略有效落地，并持续优化策略效果；</P>
      <P>5、熟悉SQL、python等数据分析工具。</P>

      <A id="pos5"></A>
      <MasterTitle>风控模型工程师</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、负责反欺诈风控模型的研发、迭代及优化工作，包括模型需求分析，模型设计，模型开发，模型评审，效果追踪等；</P>
      <P>2、负责根据用户、交易等特征结合产品，规划事前、事中、事后风险模型，并对实施的风险模型进行监控、评估和优化；</P>
      <P>3、根据业务需求，建设应用场景的机器学习模型，完成模型融合，完成机器学习模型部署；</P>
      <P>4、从非结构化数据中挖掘出个体以及网络群体的特征，不断优化和完善图特征，为反欺诈模型及策略提供有效支持。</P>

      <Title>职位要求:</Title>
      <P>1、本科及以上学历，数学、统计、计算机等专业；</P>
      <P>2、有数据挖掘或建模实习经验，对电商、支付、互金行业感兴趣优先；</P>
      <P>3、熟悉GBDT、XGBOOST、随机森林、RNN等机器学习算法；</P>
      <P>4、了解且对支付、信贷风控领域特征工程方法论感兴趣 ；</P>
      <P>5、精通SQL、Python、R、SAS等工具。 </P>

      <A id="pos6"></A>
      <MasterTitle>机器学习算法工程师</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、负责用户画像、风控模型、点击率模型等利用机器学习算法的技术探索实现；</P>
      <P>2、负责模型选型，算法建模，数据处理，以及模型训练与优化。</P>
      <Title>职位要求:</Title>
      <P>1、计算机、数学、统计学及电子信息等相关专业本科及以上学历；</P>
      <P>2、熟悉一种以上常用的深度学习计算框架，包括但不局限 Tensorflow、Mxnet、PyTorch、Caffe 等；</P>
      <P>
        3、熟悉常用机器学习算法、深度学习算法及其原理，了解主流深度学习模型，另外至少对机器学习、图像检测与识别、语音识别中的一个领域有深入理解；
      </P>
      <P>4、掌握 C++/JAVA/Python 等常用编程语言。</P>

      <A id="pos7"></A>
      <MasterTitle>数据分析师</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、梳理业务核心逻辑，构建能够刻画业务当前状态的指标体系、分析框架，并根据分析结果提出可落地的业务策略建议；</P>
      <P>2、监控主要业务指标，及时察觉数据波动并深入分析波动原因</P>
      <P>
        3、应用多类型数据科学方法解决实际问题（统计建模、机器学习、数据挖掘、因果推断等），并与业务部门进行深入的沟通合作，通过数据分析发现并解决问题，通过数据insight推动具体项目在实际业务中落地，完成分析闭环；
      </P>
      <P>4、深入理解业务，对业务中存在的问题完成深入的专题分析报告，并推动在业务中落地；</P>
      <P>5、优化和改进现有的数据统计和报表系统，提升分析效率。</P>
      <Title>职位要求:</Title>
      <P>1、全日制统招本科及以上学历，数学、统计学、计算机等相关专业优先，有相关数据分析实习经验优先；</P>
      <P>
        2、熟练使用SQL等进行数据提取；熟练使用Python、R、SPSS等任一统计分析工具；能够使用Tableau进行数据分析及可视化；
      </P>
      <P>3、具备数据思维和数据敏感度，能从数据角度发现并解决问题；</P>
      <P>
        4、具备与技术、运营、产品人员沟通数据需求的能力，具备良好的分析和解决问题的能力，具备良好的项目推动能力和执行力。
      </P>

      <A id="pos8"></A>
      <MasterTitle>Golang开发工程师</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、负责后端相关开发工作。</P>
      <Title>职位要求:</Title>
      <P>1、理解计算机原理，有良好的数据结构和算法基础和编程能力；</P>
      <P>2、理解Golang语言；</P>
      <P>3、熟悉Mysql、Redis、消息队列等基本组件；</P>
      <P>4、熟悉Linux系统，熟悉shell等脚本编程,有良好的编程习惯；</P>
      <P>5、热爱编程，有较强的学习能力，能及时关注和学习业界最新技术；</P>
      <P>6、积极乐观，责任心强，工作认真细致，有良好的团队沟通和协作能力。</P>

      {/** product*/}
      <A id="pos9"></A>
      <A id="pos10"></A>
      <MasterTitle>产品经理</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、负责OPay的前端产品设计，根据用户特性及公司业务发展方向进行产品策略制定、产品功能设计、竞品分析等工作；</P>
      <P>2、协调各方资源，完成需求分析、PRD落地，负责项目推进，跟踪上线效果，持续改善产品体验；</P>
      <P>3、通过数据分析或市场需求进行产品优化、迭代及完善，不断改进和提升产品体验，达成目标。</P>
      <Title>职位要求:</Title>
      <P>1、本科及以上学历；</P>
      <P>2、熟悉移动互联网产品，有良好的用户体验意识、很强的学习能力；</P>
      <P>3、 逻辑分析能力强，能透过事物表象分类抽象事物本质；超爱学习，在各个产品经理社区有发表自己文章者优先；</P>
      <P>4、熟练使用AXURE、VISIO、PPT、脑图等工具，沟通表达能力、文字能力和审美能力优秀；</P>
      <P>5、愿意接受反馈，具有开放心态和团队合作精神，具备协调推进项目等相关能力。</P>

      <A id="pos11"></A>
      <MasterTitle>产品运营</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、负责线上local shopping的marketplace产品设计及运营工作；</P>
      <P>2、跟踪商家和用户端的行为数据，持续改善商家和用户端的使用体验；</P>
      <P>3、对用户标签化，为推荐算法提供核心策略，提升用户活跃度；</P>
      <P>4、参与平台商家营销玩法的设计，结合用户标签，设计匹配的产品形态，提升交易用户数和单量；</P>
      <P>5、持续发现问题、提出建议并与开发团队密切配合、推动产品迭代；</P>
      <P>6、使用英文与本地运营、商务沟通合作，推动商务及跟进业务。</P>
      <Title>职位要求:</Title>
      <P>1、负责支付核心系统相关模块代码的开发及优化；</P>
      <P>2、快速学习并深入理解业务知识，独立分析、设计合理的架构、系统开发与维护，高质量交付，保障资金安全；</P>
      <P>3、解决项目开发过程中遇到的技术难点问题，以技术驱动解决业务痛点问题，同时保证系统扩展性;</P>
      <P>4、负责系统的性能优化、数据一致性保障，迎接复杂业务挑战。</P>

      {/** product*/}
      <A id="pos12"></A>
      <A id="pos13"></A>
      <MasterTitle>COE管培生</MasterTitle>
      <Title>岗位职责:</Title>
      <P>1、协助国际化HR COE团队，参与海外人力资源体系搭建，职级、薪酬福利、绩效、激励、人才发展等；</P>
      <P>2、协助COE专家，支持人力资源体系在海外各国落地。</P>
      <Title>职位要求:</Title>
      <P>1、有志向COE方向作为职业发展方向；</P>
      <P>2、思路清晰，做事主动；沟通能力强，工作语言为英文，听说读写；</P>
      <P>3、参与过COE相关项目-绩效、薪酬设计、人才发展等优先；有咨询公司、互联网行业或成熟外企相关经验优先；</P>
      <P>4、海外学习或工作经验者优先。</P>
    </Container>
  );
};

Info.defaultProps = {
  position: "",
};

Info.propTypes = {
  position: PropTypes.string,
};

export default Info;
