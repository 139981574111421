import React from "react";
import online from "../../../../images/product/online.png";
import interview from "../../../../images/product/interview.png";
import send from "../../../../images/product/send.png";
import sj from "../../../../images/product/sj.png";
import { Container, Title, Flex, FlexItem, Img, SjImg, Label, Space } from "./style";

const Step = () => {
  return (
    <Container>
      <Title>Recruitment Process</Title>
      <Flex>
        <FlexItem>
          <Img src={online} />
        </FlexItem>
        <SjImg src={sj} />
        <FlexItem>
          <Img src={interview} />
        </FlexItem>
        <SjImg src={sj} />
        <FlexItem>
          <Img src={send} />
        </FlexItem>
      </Flex>
      <Flex>
        <FlexItem>
          <Label>Online application</Label>
        </FlexItem>
        <Space></Space>
        <FlexItem>
          <Label>Interview</Label>
        </FlexItem>
        <Space></Space>
        <FlexItem>
          <Label>Send Offer</Label>
        </FlexItem>
      </Flex>
    </Container>
  );
};

export default Step;
