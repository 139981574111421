import styled from "styled-components";

const Container = styled.div`
  background: #f9f9f9;
  padding: 60px 0 30px;
  text-align: center;

  ${({ theme }) => theme.mq.md`
      padding: 110px 0 100px;
  `}
`;

const Title = styled.div`
  font-family: Graphik-Black;
  font-size: 18px;
  color: #222f3e;
  letter-spacing: 0;
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;

  ${({ theme }) => theme.mq.md`
    font-family: Graphik-Black;
    font-size: 36px;
    color: #222f3e;
    letter-spacing: 0;
    text-align: center;
    font-weight: bold;
    margin-bottom: 55px;
  `}
`;

const Label = styled.div`
  font-family: Graphik-Bold;
  font-size: 14px;
  color: #222f3e;
  letter-spacing: 0;
  text-align: center;
  font-weight: 700;
  margin-top: 20px;

  ${({ theme }) => theme.mq.md`
    font-family: Graphik-Bold;
    font-size: 24px;
    color: #222f3e;
    letter-spacing: 0;
    text-align: center;
    font-weight: 700;
    margin-top: 43px;
  `}
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FlexItem = styled.div`
  text-algn: center;
  margin-right: 20px;
  width: 80px;
  &:last-child {
    margin-right: 0px;
  }

  ${({ theme }) => theme.mq.md`
    text-algn: center;
    margin-right: 60px;
    width: 220px;
  `}
`;
const Img = styled.img`
  height: 43px;

  ${({ theme }) => theme.mq.md`
    height: 86px;
  `}
`;

const Space = styled.div`
  width: 15px;
  height: 13px;
  margin-right: 20px;

  ${({ theme }) => theme.mq.md`
    width: 22px;
    height: 22px;
    margin-right: 60px;
  `}
`;

const SjImg = styled.img`
  width: 15px;
  height: 13px;
  margin-right: 20px;

  ${({ theme }) => theme.mq.md`
    width: 22px;
    height: 22px;
    margin-right: 60px;
  `}
`;
export { Container, Flex, Title, FlexItem, Img, SjImg, Label, Space };
