import React from "react";
import PropTypes from "prop-types";
// import { graphql, useStaticQuery } from "gatsby";
import { Container, MasterTitle, Title, P, A } from "../../common/info/style";

const Info = props => {
  const scrollToAnchor = anchorName => {
    if (anchorName) {
      if (typeof window !== `undefined`) {
        let anchorElement = document.getElementById(anchorName);
        if (anchorElement) {
          anchorElement.scrollIntoView(100);
        }
      }
    }
  };
  scrollToAnchor("pos" + props.position);

  return (
    <Container>
      <A id="pos1"></A>
      <MasterTitle>Java developer</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Responsible for product design, research and development, and maintenance based on requirements of payment
        products;
      </P>
      <P>2、Responsible for the design and coding of the company's core modules;</P>
      <P>3、Organize and compile relevant design and development documents;</P>
      <P>4、Coordinate with teams of product, operation, and test engineers;</P>
      <P>5、Responsible for product innovation and technological innovation;</P>
      <P>
        6、Understand the operation bottlenecks of large websites or network systems, as well as the corresponding
        technological solutions;
      </P>
      <P>
        7、Have a strong sense of responsibility, cheerful personality, problem analysis ability, and teamwork spirit.
      </P>
      <Title>Job Requirements:</Title>
      <P>1、Bachelor’s degree or above, proficient in using git version control system and Linux commands;</P>
      <P>2、Solid background JAVA language and good coding habits;</P>
      <P>
        3、Familiar with relational databases such as Mysql and non-relational databases such as Elasticsearch and
        Redis;
      </P>
      <P>4、Familiar with the microservice technology stack, including SpringBoot, SpringCloud, RPC framework, etc.;</P>
      <P>5、Familiar with message-oriented middleware such as RocketMq and Kafka;</P>
      <P>6、Solid background in back-end development, including data structure, multithreading, memory, JDK.</P>

      <A id="pos2"></A>

      <MasterTitle>Web Front-end Developer</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Based on product design requirements, cooperate with back-end developers to create product interfaces and
        functions, and maintain and optimize front-end web performance;
      </P>
      <P>
        2、Design and create WEB front-end architecture and applications, and design and set up a general business
        components library;
      </P>
      <P>3、Work with teams of product, UI, and backend to continuously improve user experience.</P>
      <Title>Job Requirements:</Title>
      <P>1、Bachelor’s degree or above;</P>
      <P>2、Solid background in the front end, and excellent coding habits;</P>
      <P>3、Good understanding of front-end engineering, network, security, and performance optimization;</P>
      <P>4、Deep understanding of technology stacks, including Vue, React, and Webpack;</P>
      <P>5、Back-end experience (Node, Java, etc.) is preferred.</P>

      <A id="pos3"></A>
      <MasterTitle>Risk Control Strategy Analyst</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Use product analysis, business logic analysis, case traceability, and other methods to identify and extract
        features of risks for OPay business, such as false transactions, cheating, and payment fraud;
      </P>
      <P>
        2、Establish a risk model for data monitoring to contribute your personal opinions to risk prevention
        strategies;
      </P>
      <P>
        3、Coordinate with product, research, and business operation departments to promote continuous optimization of
        strategies;
      </P>
      <Title>Job Requirements:</Title>
      <P>
        1、Bachelor’s degree or above, with internships or work experience in payment, e-commerce, credit risk control
        strategy, or data analysis;
      </P>
      <P>2、Knowledge of risk management strategy system;</P>
      <P>3、Sensitive to data and strong anti-fraud logical thinking;</P>
      <P>
        4、Good communication and project advancement abilities to effectively carry out and continuously optimize risk
        control strategies;
      </P>
      <P>5、Familiar with data analysis tools, such as SQL and python. </P>

      <A id="pos5"></A>
      <MasterTitle>Risk Control Model Engineer</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Responsible for the research and development, iteration, and optimization of the anti-fraud risk control
        model, including model requirement analysis, model design, model development, model review, effect monitoring,
        etc.;
      </P>
      <P>
        2、Establish pre-, during, and post-event risk models for products based on the characteristics of users and
        transactions, and monitor, evaluate, and optimize the implemented risk models;
      </P>
      <P>
        3、Based on business requirements, build machine learning models for application scenarios, complete model
        fusion, and deploy machine learning models;
      </P>
      <P>
        4、Explore characteristics of individuals and network groups from unstructured data, and continuously optimize
        the features of graphs to support anti-fraud models and strategies.
      </P>
      <Title>Job Requirements:</Title>
      <P>1、Bachelor’s degree or above, major in mathematics, statistics, computer, etc.;</P>
      <P>
        2、Internship experience in data mining or modeling; interest in e-commerce, payment, and Internet finance
        industries is preferred;
      </P>
      <P>3、Familiar with machine learning algorithms, such as GBDT, XGBOOST, random forest, and RNN;</P>
      <P>
        4、Understand and have interest in feature engineering techniques in the fields of payment and credit risk
        control;
      </P>
      <P>5、Proficient in SQL, Python, R, SAS, and other tools. </P>

      <A id="pos6"></A>
      <MasterTitle>Machine Learning Algorithm Engineer</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Use machine learning algorithms to create user portraits, risk control models, click-through rate models,
        etc.;
      </P>
      <P>
        2、Responsible for model selection, algorithm modeling, data processing, and model training and optimization;
      </P>
      <Title>Job Requirements:</Title>
      <P>
        1、Bachelor’s degree or above in computer, mathematics, statistics, electronic information, and other related
        majors.
      </P>
      <P>
        2、Familiar with at least one deep learning computing framework, including but not limited to Tensorflow, Mxnet,
        PyTorch, and Caffe;
      </P>
      <P>
        3、Familiar with commonly used machine learning algorithms, deep learning algorithms, and their principles,
        understand mainstream deep learning models, and have an in-depth understanding of at least one area in ​​machine
        learning, image detection and recognition, and speech recognition;
      </P>
      <P>4、Master popular programming languages​,​such as C++, JAVA, and Python.</P>

      <A id="pos7"></A>
      <MasterTitle>Data analyst</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Understand the core business logic, build indicator systems and analysis frameworks for current projects, and
        put forward feasible business strategy proposals based on the analysis results;
      </P>
      <P>2、Monitor major business indicators, detect data fluctuations in time, and analyze the causes;</P>
      <P>
        3、Apply multiple data science techniques (statistical modeling, machine learning, data mining, causal
        inference, etc.) to solve practical problems, cooperate with business departments to discover and solve problems
        through data analysis, and implement specific projects based on the data insight to complete the closed-loop
        analysis;
      </P>
      <P>
        4、Understand the business, write in-depth special analysis reports on existing problems in the business, and
        work to solve those problems;
      </P>
      <P>5、Improve the existing data statistics and reporting system to enhance analysis efficiency.</P>
      <Title>Job Requirements:</Title>
      <P>
        1、Full-time Bachelor’s degree or above; majors in mathematics, statistics, computer, and other related fields
        are preferred; internship in data analysis is preferred;
      </P>
      <P>
        2、Proficient in SQL data extraction and in using statistical analysis tools, such as Python, R, SPSS, etc.;
        familiar with using Tableau for data analysis and visualization;
      </P>
      <P>3、Data thinking and data sensitivity, with the ability to detect and solve problems via data analysis;</P>
      <P>
        4、Ability to communicate data requirements with technological, operational, and product personnel; good
        analysis and problem-solving skills; ability to advance and execute projects{" "}
      </P>

      <A id="pos8"></A>
      <MasterTitle>Golang developer</MasterTitle>
      <Title>Job Description:</Title>
      <P>1、Responsible for back-end development.</P>
      <Title>Job Requirements:</Title>
      <P>1、Understand computer principles and skilled in data structures and algorithms and programming;</P>
      <P>2、Understand the Golang language;</P>
      <P>3、Familiar with basic components, such as Mysql, Redis, and message queues;</P>
      <P>4、Familiar with Linux system and shell script programming; have good programming habits;</P>
      <P>
        5、Quick learner with enthusiasm for programming and the ability to follow up on the latest technology in time;
      </P>
      <P>6、Positive, responsible, and detail-oriented at work with good communication and collaboration skills.</P>

      {/** product*/}
      <A id="pos9"></A>
      <A id="pos10"></A>
      <MasterTitle>Product Manager</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Responsible for designing OPay's front-end products, including formulating product strategies, designing
        product functions, and conducting competitive products analysis according to user characteristics and our
        business development plans;
      </P>
      <P>
        2、Coordinate resources to conduct requirements analysis, PRD implementation, project advancement, and user
        feedback follow-up to continuously improve product experience;
      </P>
      <P>
        3、Carry out product optimization and iteration based on data analysis or market demand, and keep enhancing
        product experience to reach our goals.
      </P>
      <Title>Job Requirements:</Title>
      <P>1、Bachelor’s degree or above;</P>
      <P>2、Familiar with mobile Internet products; have a good sense of user experience and fast learning ability;</P>
      <P>
        3、Strong logical and analytical abilities to generalize the essence from appearance; enthusiastic about
        learning, candidates with articles published in various product manager communities are preferred;
      </P>
      <P>
        4、Proficiency in using AXURE, VISIO, PPT, mind map and other tools, excellent communication skills, writing
        skills, and aesthetic judgment;
      </P>
      <P>5、Have an open mind for feedback, a teamwork spirit, and the ability to coordinate and promote projects.</P>

      <A id="pos11"></A>
      <MasterTitle>Product operation</MasterTitle>
      <Title>Job Description:</Title>
      <P>1、Responsible for design and operation of marketplace products in local shopping;</P>
      <P>2、Track the behavior data of merchants and users, and continuously improve product experience for them;</P>
      <P>
        3、Label different groups of users and provide core strategies for recommendation algorithms to improve user
        activation;
      </P>
      <P>
        4、Design marketing approaches for merchants on the platform, design matching products catering to user
        features, and increase the number of users and orders;
      </P>
      <P>
        5、Detect problems, make suggestions, and work closely with the development team to promote product iteration;
      </P>
      <P>
        6、Communicate and cooperate with the local operation and business personnel in English to advance our business.
      </P>
      <Title>Job Requirements:</Title>
      <P>1、Develop and optimize module codes of the core payment system;</P>
      <P>
        2、Master our business fastly, analyze and design feasible architecture independently, take on system
        development and maintenance, and ensure high-quality delivery to guarantee the security of funds;
      </P>
      <P>
        3、Solve technological difficulties in the process of project development, and solve business pain points in a
        technology-driven manner, and ensure system scalability as well;
      </P>
      <P>
        4、Responsible for system performance optimization and data consistency, and dare to take on complex business
        challenges.
      </P>

      {/** product*/}
      <A id="pos12"></A>
      <A id="pos13"></A>
      <MasterTitle>COE Management Trainee</MasterTitle>
      <Title>Job Description:</Title>
      <P>
        1、Assist the global HR COE team in establishing an overseas human resource system in terms of ranks, salary and
        benefits, performance, incentives, talent development, etc.;
      </P>
      <P>2、Assist COE experts to implement the human resource system overseas.</P>
      <Title>Job Requirements:</Title>
      <P>1、Ambition in taking COE as your career development goal;</P>
      <P>2、Clear-headed and proactive at work, with strong communication skills and English as working language;</P>
      <P>
        3、Participation in COE related projects in terms of performance, salary design, and talent development is
        preferred; experience in consultancies, Internet industry, or mature foreign companies is preferred;
      </P>
      <P>4、Overseas study or work experience is preferred.</P>
    </Container>
  );
};

Info.defaultProps = {
  position: "",
};

Info.propTypes = {
  position: PropTypes.string,
};

export default Info;
