import React from "react";
import online from "../../../../images/product/online.png";
import interview from "../../../../images/product/interview.png";
import send from "../../../../images/product/send.png";
import sj from "../../../../images/product/sj.png";
import { Container, Title, Flex, FlexItem, Img, SjImg, Label, Space } from "./style";

const Step = () => {
  return (
    <Container>
      <Title>招聘流程</Title>
      <Flex>
        <FlexItem>
          <Img src={online} />
        </FlexItem>
        <SjImg src={sj} />
        <FlexItem>
          <Img src={interview} />
        </FlexItem>
        <SjImg src={sj} />
        <FlexItem>
          <Img src={send} />
        </FlexItem>
      </Flex>
      <Flex>
        <FlexItem>
          <Label>网申/内推</Label>
        </FlexItem>
        <Space></Space>
        <FlexItem>
          <Label>面试</Label>
        </FlexItem>
        <Space></Space>
        <FlexItem>
          <Label>发放Offer</Label>
        </FlexItem>
      </Flex>
    </Container>
  );
};

export default Step;
